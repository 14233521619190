import axios from 'axios'

import { baseURL } from 'config/constants'

const responseHandler = response => {
  const {
    data: { data, error, success },
  } = response
  if (success) {
    return data
  } else {
    return Promise.reject(error)
  }
}
const API = {}

const axiosInstance = axios.create({
  baseURL,
  validateStatus() {
    return true
  },
})

axiosInstance.interceptors.response.use(responseHandler, error =>
  Promise.reject(error),
)

API.getCorporation = publicUrl =>
  axiosInstance.get(`/admin/corporation`, { params: { publicUrl } })

API.getTheme = corporationId =>
  axiosInstance.get(`/admin/corporation/theme`, { params: { corporationId } })

API.getNeighborData = ({ rut, unitId, lineId, isReservation }) =>
  axiosInstance.get(`/integration/lascondes/validateneighbor`, {
    params: { rut, unitId, lineId, isReservation },
  })

API.getReservations = ({
  scheduleUnitId,
  date,
  onlyPublic,
  appointmentStatuses = [1, 3],
}) =>
  axiosInstance.get(`/schedule/public/appointments`, {
    params: {
      date,
      scheduleUnitId,
      onlyPublic,
      appointmentStatuses,
    },
  })

API.getNextAvailable = payload =>
  axiosInstance.get(`/schedule/public/nextAppointment`, {
    params: payload,
  })

API.findAppointment = ({ patientRut, corporationId }) =>
  axiosInstance.get(`/schedule/public/findAppointments`, {
    params: {
      patientRut,
      corporationId,
    },
  })

API.confirmAppointment = ({ token, appointmentStatusId }) =>
  axiosInstance.patch(`/schedule/public/confirmAppointment`, {
    token,
    appointmentStatusId,
  })

API.cancelReservation = ({ token, isCancellation }) =>
  axiosInstance.patch(`/schedule/public/reservation/cancelReservation`, {
    token,
    isCancellation,
  })

API.requestAppointmentCancelation = appointmentId =>
  axiosInstance.get(`schedule/public/requestConfirmation/${appointmentId}`)

API.requestLineCancelation = lineId =>
  axiosInstance.get(`schedule/public/requestConfirmation/line/${lineId}`)

API.getAppointments = payload =>
  axiosInstance.get(`/schedule/public/appointments`, { params: payload })

API.makeAppointment = payload =>
  axiosInstance.patch(`schedule/public/makeAppointment`, payload)

API.getServices = corporationId =>
  axiosInstance.get(`schedule/public/services`, { params: { corporationId } })

API.getLine = lineId => axiosInstance.get(`schedule/public/lines/${lineId}`)

API.getLines = (unitId, { lineCategoryId, withCurrentTicket, isPublic }) =>
  axiosInstance.get(`schedule/public/lines`, {
    params: { unitId, lineCategoryId, withCurrentTicket, isPublic },
  })

API.getUnit = unitId => axiosInstance.get(`schedule/public/unit/${unitId}`)

API.getAvailableReservations = (params = {}) =>
  axiosInstance.get(`schedule/public/reservations`, {
    params,
  })

API.getNextAvailableReservation = params =>
  axiosInstance.get(`schedule/public/nextReservation`, { params })

API.generateReservation = payload => {
  const data = new FormData()
  data.append(`payload`, JSON.stringify(payload))
  data.append(`file`, payload.file)
  return axiosInstance.post(`schedule/public/generateReservation`, data)
}
API.findReservations = params =>
  axiosInstance.get(`schedule/public/findReservations`, { params })

API.getAvailableReservationDays = ({ lineId, numberOfMonth }) =>
  axiosInstance.get(`schedule/public/getAvailableReservationDays`, {
    params: { lineId, numberOfMonth },
  })

API.getScheduleUnit = id =>
  axiosInstance.get(`schedule/public/getScheduleUnit`, {
    params: { id },
  })

API.getProfessionals = scheduleUnitId =>
  axiosInstance.get(`schedule/public/professional`, {
    params: { scheduleUnitId },
  })

// DESPACHOS

API.createValidPatient = patient =>
  axiosInstance.post(`admin/validPatient/create`, { ...patient })

API.validatePatient = params =>
  axiosInstance.get(`schedule/public/validPatient`, { params })

API.updatePatient = payload =>
  axiosInstance.put(`schedule/public/validPatient/update`, payload)

API.generateDeliveryRequest = payload =>
  axiosInstance.post(`schedule/public/deliveries/createRequest`, payload)

API.uploadPrescription = validPatientId =>
  `${baseURL}/schedule/public/validPatient/uploadPrescription?validPatientId=${validPatientId}`

API.createPrescription = payload =>
  axiosInstance.post(`schedule/public/prescription/create`, payload)

API.getHealthInstitutions = payload =>
  axiosInstance.get(`schedule/public/healthinstitutions`, {
    params: payload,
  })
API.getMedicalSpecialties = ({ unitId }) =>
  axiosInstance.get(`admin/unit/medicalSpecialties`, {
    params: { unitId },
  })

API.getCommunes = regionId =>
  axiosInstance.get(`schedule/public/communes`, { params: { regionId } })

API.getRegions = () => axiosInstance.get(`schedule/public/regions`)

API.createPickup = pickup => {
  const { prescriptions, reservationData, pickupStatusId } = pickup
  const data = new FormData()
  data.append(`file`, reservationData.file)
  data.append(`prescriptions`, JSON.stringify(prescriptions))
  data.append(`reservationData`, JSON.stringify(reservationData))
  data.append(`pickupStatusId`, `${pickupStatusId}`)
  return axiosInstance.post(`schedule/public/pickup/create`, data)
}
API.verifyDelivery = ({
  rut,
  dv,
  unitId,
  medicalSpecialtyId,
  hasDeliveryIntegrationFlow,
}) =>
  axiosInstance.get(`schedule/public/verifyDelivery`, {
    params: { dv, rut, unitId, medicalSpecialtyId, hasDeliveryIntegrationFlow },
  })

// LINE
API.generateTicket = payload =>
  axiosInstance.post(`schedule/public/generateTicket`, payload)

API.generateTicketWithRut = payload =>
  axiosInstance.post(`schedule/public/generateTicketWithRut`, payload)

API.findTicket = params =>
  axiosInstance.get(`schedule/public/findTicket`, {
    params,
  })

API.newVisit = lineId =>
  axiosInstance.patch(`schedule/public/newVisit/${lineId}`)

//EMAIL CONFIRMATION -MODULO CONFIRMACION HORAS-
API.receivedResponse = ({
  token,
  response,
  scheduledAppointmentId,
  programProtocolId,
}) =>
  axiosInstance.put(
    `hourConfirmation/receivedResponse`,
    {
      response,
      scheduledAppointmentId,
      programProtocolId,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  )

//BUSCAR PACIENTE POR RUT CON FUNCION DE INTEGRACION
API.getPatientByRut = ({ rut, corporationId }) =>
  axiosInstance.get(`schedule/public/getPatientByRut`, {
    params: { rut, corporationId },
  })

//CREAR PACIENTE
API.createPatient = payload =>
  axiosInstance.post(`schedule/public/createPatient`, payload)

//BUSCAR PACIENTE POR RUT
API.getPatientWithRut = ({ rut, dv, token }) =>
  axiosInstance.get(`admin/patient`, {
    params: { rut, dv },
    headers: { Authorization: `Bearer ${token}` },
  })
//ACTUALIZAR PACIENTE CLAVEUNICA
API.upsertPatient = (payload, token) =>
  axiosInstance.put(`admin/patient/claveunica-update`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })

// NPS
API.getNpsQuestions = ({ token, programProtocolId, npsAppointmentId }) =>
  axiosInstance.get(
    `nps/nps-appointment/${npsAppointmentId}/program-protocol/${programProtocolId}/questions`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )

API.getNpsSuccessMessagesConfig = ({ token, programProtocolId }) =>
  axiosInstance.get(`nps/config/program-protocol/${programProtocolId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })

//EMAIL CONFIRMATION -MODULO CONFIRMACION HORAS-
API.receivedNpsResponse = (token, body) =>
  axiosInstance.put(`nps/receivedResponse`, body, {
    headers: { Authorization: `Bearer ${token}` },
  })

// Reservation lock timer
API.isReservationAvailable = (lineId, selectedHour) =>
  axiosInstance.get(
    `schedule/public/isReservationAvailable/${lineId}/${selectedHour}`,
  )

API.addReservationTemporalBlock = payload =>
  axiosInstance.post(`schedule/public/addReservationTemporalBlock`, payload)

API.removeReservationTemporalBlock = payload =>
  axiosInstance.post(`schedule/public/removeReservationTemporalBlock`, payload)

// Google Captcha
API.getInvisibleSiteKey = () =>
  axiosInstance.get(`/captcha/getInvisibleSiteKey`)

export default API
