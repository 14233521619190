/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { format, clean } from 'rut.js'
import moment from 'moment-timezone'
import notification from 'antd/es/notification'
import { useParams } from 'react-router-dom'

import { useBack, useStateWithMerge } from 'hooks'
import { useAlert, useCorporation } from 'context'
import { Container, Typography, Icon, Link, Form, Button } from 'components'
import API from 'config/apiAgenda'
import { CORPS_WITH_PATIENT_QUERY } from 'config/constants'
import ReservationRequestByPatient from './ReservationRequestByPatient'

function SuccessScreen({
  history,
  reservationId,
  date,
  patientMail,
  lineName,
}) {
  const goBack = useBack()
  return (
    <Container
      width="100%"
      flexDirection="column"
      alignItems={{ md: `center` }}
      paddingX={4}
    >
      <Container
        width={{ _: `100%`, md: `35%` }}
        flexDirection="column"
        alignItems="flex-start"
      >
        <Container width="100%" flexDirection="column">
          <Typography
            color="primary.2"
            fontSize="6"
            fontWeight="bold"
            fontStyle="italic"
            marginY="10px"
          >
            ¡Reserva Nº {reservationId} exitosa!
          </Typography>
          <Typography
            color="primary.2"
            fontSize="4"
            fontStyle="italic"
            marginY="10px"
          >
            {`Enviado al correo registrado: ${patientMail}`}
          </Typography>
        </Container>
        <Container
          flexDirection="column"
          width="100%"
          border="1px solid"
          borderColor="primary.2"
          padding="2"
        >
          <Typography
            color="primary.2"
            fontSize="4"
            fontWeight="bold"
            textAlign="center"
            marginTop="2"
            marginBottom="4"
            paddingX="2"
          >
            Fecha:{` `}
            {moment(date, `YYYY-MM-DD HH:mm:ss`).format(`LL`)}
          </Typography>
          <Container
            width="97%"
            height="2px"
            backgroundColor="primary.2"
            margin="0 auto"
          />
          <Typography
            color="primary.2"
            fontSize="4"
            fontWeight="bold"
            textAlign="center"
            marginY="4"
            paddingX="2"
          >
            Hora: {moment(date).format(`H:mm`)} hrs.
          </Typography>
          <Container
            width="97%"
            height="2px"
            backgroundColor="primary.2"
            margin="0 auto"
          />
          <Typography
            color="primary.2"
            fontSize="4"
            fontWeight="bold"
            textAlign="center"
            marginTop="4"
            marginBottom="2"
            paddingX="2"
          >
            Área: {lineName}
          </Typography>
        </Container>
        <Container width="100%" justifyContent="space-between" marginTop="20px">
          <Button
            padding="10px"
            paddingX="2"
            backgroundColor="primary.2"
            width="49%"
            onClick={goBack}
          >
            VOLVER A RESERVAR
          </Button>
          <Button
            padding="10px"
            backgroundColor="primary.2"
            width="49%"
            onClick={() => window.print()}
          >
            <Icon icon="print" marginRight="5px" />
            IMPRIMIR
          </Button>
        </Container>
        <Typography textAlign="center" color="error" marginY="3" paddingX="2">
          Le recordamos tomar una captura de pantalla para guardar su número de
          atención y presentarla al llegar.
        </Typography>
        <Link
          onClick={() => {
            history.push(`/`)
          }}
          color="primary.0"
          hoverProps={{ color: `secundary.0` }}
          fontSize={{ _: 1, lg: 4 }}
          fontStyle="italic"
          justifyContent="center"
          width="100%"
        >
          <Typography>Volver al menú principal</Typography>
        </Link>
      </Container>
    </Container>
  )
}

const initialState = {
  patientRut: null,
  patientMail: null,
  patientFirstName: null,
  patientLastName: null,
  patientPhone: null,
  file: null,
  isLoading: false,
  errors: {
    patientFirstName: null,
    patientLastName: null,
    patientPhone: null,
    patientRut: null,
    patientMail: null,
  },
  success: false,
  reservation: {},
  line: null,
  formSchema: {},
  rutQuery: false,
  existingPatient: false,
  timer: 0,
  timerWasSet: false,
}

// eslint-disable-next-line complexity
function ReservationRequest({ history, match, location }) {
  const [state, setState] = useStateWithMerge(initialState)
  const { setAlert } = useAlert()
  const goBack = useBack()
  const { lineId, reservationDate } = useParams()
  // ESTO ES SOLO PARA HOTFIX DE LAS CONDES
  // SE BLOQUEAN INPUTS SOLO PARA LAS CONDES
  const { id: corporationId } = useCorporation()
  const disabledInputs = [1].includes(corporationId)

  const {
    patientId,
    patientRut,
    patientMail,
    patientFirstName,
    patientLastName,
    patientPhone,
    file,
    isLoading,
    success,
    reservation,
    line,
    prescriptions,
    formSchema,
    rutQuery,
    existingPatient,
    timer,
    timerWasSet,
  } = state

  async function removeReservationTemporalBlock() {
    if (patientRut) {
      try {
        const [rut] = format(patientRut).split(`-`)
        const rutAsInt = parseInt(clean(rut), 10)

        await API.removeReservationTemporalBlock({
          lineId,
          reservationDate,
          patientRut: rutAsInt,
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error unblocking time slot:`, error)
      }
    }
  }

  useEffect(() => {
    async function getLine() {
      try {
        const patient = location.state ? location.state.patient : null
        let formatedPatient = {}
        if (patient) {
          formatedPatient = {
            patientId: patient.id,
            patientRut: `${patient.rut}${patient.dv}`,
            patientMail: patient.email,
            patientFirstName: patient.firstName,
            patientLastName: patient.lastName,
            patientPhone: patient.phone,
            prescriptions: patient.prescriptions,
            errors: {},
          }
        }
        const line = location.state
          ? location.state.line
          : await API.getLine(match.params.lineId)
        if (line.publicConfig) {
          setAlert(line.publicConfig.alert)
        }
        const formType = line.validatePatient
          ? `withValidatePatient`
          : `withoutValidatePatient`
        setState({
          line,
          formSchema: line.publicForms[formType],
          ...formatedPatient,
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
    getLine()
  }, [])

  useEffect(() => {
    return () => {
      if (timerWasSet) {
        removeReservationTemporalBlock()
      }
    }
  }, [patientRut])

  useEffect(() => {
    let timerInterval

    if (timer > 0 && rutQuery) {
      timerInterval = setInterval(() => {
        setState(prevState => ({
          timer: prevState.timer - 1,
        }))
      }, 1000)
    }

    if (timer === 0 && timerWasSet) {
      removeReservationTemporalBlock()
    }

    return () => clearInterval(timerInterval)
  }, [timer, rutQuery])

  async function generateReservation(patient) {
    setState({ isLoading: true, error: null })

    if (
      CORPS_WITH_PATIENT_QUERY.includes(corporationId) &&
      existingPatient == false
    ) {
      try {
        const payload = {
          rut: patient.patientRut,
          firstName: patient.patientFirstName,
          lastName: patient.patientLastName,
          email: patient.patientMail,
          phone: parseInt(patient.patientPhone, 10),
          corporationId,
        }
        await API.createPatient(payload)
      } catch (error) {
        notification.error({
          message: error,
        })
      }
    }

    try {
      const [rut, dv] = format(patient.patientRut).split(`-`)
      const payload = {
        ...patient,
        patientRut: parseInt(clean(rut), 10),
        patientRutDv: dv,
        lineId: match.params.lineId,
        reservationDate: moment(match.params.reservationDate).format(
          `YYYY-MM-DD HH:mm`,
        ),
        autoConfirm: true,
      }

      let reservationResult
      if (line.hasPickup) {
        const { reservation } = await API.createPickup({
          reservationData: payload,
          pickupStatusId: 4,
          prescriptions: prescriptions ? prescriptions : [],
          file: patient.file,
        })
        if (patientId) {
          await API.updatePatient({
            id: patientId,
            ...patient,
            email: patient.patientMail,
            phone: patient.patientPhone,
          })
        }
        reservationResult = reservation
      } else {
        const { reservation } = await API.generateReservation(payload)
        reservationResult = reservation
      }
      setState({
        reservation: reservationResult,
        patientMail: patient.patientMail,
        isLoading: false,
        success: true,
      })
    } catch (e) {
      const error =
        typeof e === `string` ? e : `Ha ocurrido un error, reintente.`
      notification.error({
        message: error,
      })
      goBack()
      setState({ error, isLoading: false })
    }
  }

  async function patientRutQuery(patientRut) {
    setState({ isLoading: true })

    try {
      const [rut] = format(patientRut).split(`-`)
      const rutAsInt = parseInt(clean(rut), 10)
      const response = await API.addReservationTemporalBlock({
        patientRut: rutAsInt,
        lineId,
        reservationDate,
      })

      const newTimer = response.timeToExpireInMinutes * 60
      const shouldSearchPatient = CORPS_WITH_PATIENT_QUERY.includes(
        corporationId,
      )

      let newState = {
        timer: newTimer,
        timerWasSet: true,
      }

      if (!shouldSearchPatient) {
        newState = {
          ...newState,
          patientRut,
          rutQuery: true,
          isLoading: false,
        }
      }

      setState(newState)

      if (shouldSearchPatient) {
        try {
          // Validar si el patient ya existe
          const patient = await API.getPatientByRut({ rut, corporationId })
          setState({
            patientRut,
            patientMail: patient.email,
            patientFirstName: patient.nombre,
            patientLastName: patient.apellido,
            patientPhone: patient.celular,
            rutQuery: true,
            existingPatient: true,
            isLoading: false,
          })
        } catch (error) {
          notification.warn({
            message: `No se encontró paciente asociado a ese RUT, rellene los campos.`,
          })
          setState({ patientRut, rutQuery: true, isLoading: false })
        }
      }
    } catch (error) {
      // Ocurrio un error al bloquear la reserva
      notification.warn({
        message: error ?? `Ha ocurrido un error, reintente.`,
      })
      goBack()
    }
  }

  return success ? (
    <SuccessScreen
      history={history}
      lineName={line.name}
      date={reservation.reservationDate}
      patientMail={patientMail}
      reservationId={reservation.id}
    />
  ) : line == null ? (
    <Container width="100%" justifyContent="center" alignItems="center">
      <Icon icon="spinner" spin color="primary.2" fontSize="10" />
    </Container>
  ) : (
    <Container
      width="100%"
      flexDirection="column"
      alignItems="center"
      paddingX={4}
    >
      <Container
        width="100%"
        paddingBottom="4"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Typography
          width="100%"
          color="primary.0"
          fontSize={{ _: 5, lg: 8 }}
          fontWeight="bold"
        >
          Confirmación de datos personales
        </Typography>
      </Container>
      {rutQuery && (
        <Typography color="primary.0" fontSize="4">
          Tiempo restante: {Math.floor(timer / 60)}:{`0${timer % 60}`.slice(-2)}
          {` `}
          minutos
        </Typography>
      )}
      {!rutQuery ? (
        <ReservationRequestByPatient
          patientRutQuery={patientRutQuery}
          isLoading={isLoading}
        />
      ) : (
        <Form
          disabledInputs={disabledInputs}
          maxWidth="700px"
          formSchema={formSchema}
          selectedValues={{
            patientRut,
            patientMail,
            patientFirstName,
            patientLastName,
            patientPhone,
            file,
          }}
          okText="Aceptar y finalizar"
          onConfirm={generateReservation}
          isLoading={isLoading}
        />
      )}
    </Container>
  )
}

export default ReservationRequest
