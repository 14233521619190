/* eslint-disable complexity */
import moment from 'moment-timezone'
import message from 'antd/es/message'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { useStateWithMerge } from 'hooks'
import { Popover } from 'antd'
import API from 'config/apiAgenda'
import Container from './Container'
import Typography from './Typography'
import Button from './Button'
import Icon from './Icon'

import 'antd/es/popover/style/index.css'

const getInitialState = nextAvailableDate => ({
  error: ``,
  currentDate: nextAvailableDate,
  selectedReservation: null,
  historyStack: [nextAvailableDate],
})

function HourDetail({ total, available, taken }) {
  return (
    <Container flexDirection="column">
      <Typography>Total: {total}</Typography>
      <Typography>Disponibles: {available} </Typography>
      <Typography>Reservadas: {taken} </Typography>
    </Container>
  )
}

// eslint-disable-next-line max-lines-per-function
function HourPicker({
  hours,
  isLoading,
  request,
  dependencies = [],
  getPath,
  nextAvailableDate = moment(),
  styles: stylesDictionary,
  openCalendar,
  selectedDayInTheCalendar,
  availableDays = [],
  history,
  lineId,
}) {
  const [state, setState] = useStateWithMerge(
    getInitialState(nextAvailableDate),
  )
  const { currentDate, selectedReservation, historyStack } = state

  useEffect(() => {
    // TODO: Darle una revisada a esto
    request({ date: nextAvailableDate, isForward: false })
  }, dependencies)

  useEffect(() => {
    if (moment.isMoment(nextAvailableDate)) {
      setState({ currentDate: nextAvailableDate })
    }
  }, [nextAvailableDate])

  useEffect(() => {
    if (selectedDayInTheCalendar) {
      setState({
        historyStack: [...historyStack, currentDate],
        currentDate: moment(selectedDayInTheCalendar),
      })
    }
  }, [selectedDayInTheCalendar])

  useEffect(() => {
    const firstActive = hours.find(hour => hour.active)
    setState({ selectedReservation: firstActive ? firstActive.id : null })
  }, [hours])

  function handleBackPress() {
    const copy = [...historyStack]
    const lastViewedDate = copy.pop()
    if (historyStack == null) {
      return message.error(
        `No se puede volver atrás, reintente con otra fecha por favor`,
      )
    }
    setState({
      currentDate: lastViewedDate,
      historyStack: copy,
    })
    request({ date: lastViewedDate, isForward: false })
  }

  async function handleForwardPress() {
    // si request devuelve datos significa que para el dia siguiente
    // hay reservas disponibles, recién ahí pushear al historyStack

    try {
      const response = await request({ date: currentDate, isForward: true })

      if (response.nextAvailableDate) {
        setState({
          historyStack: [...historyStack, currentDate],
        })
      }
    } catch (e) {
      return message.error(`No hay más dias disponibles para reservar`)
    }
  }

  async function handleScheduleClick() {
    if (!selectedReservation) {
      return
    }

    try {
      const redirectConfig = getPath(selectedReservation)

      if (lineId) {
        if (!redirectConfig?.state?.selectedHour) {
          message.error(
            `No se puede realizar la reserva en este momento. Recargue la página y reintente.`,
          )
          return
        }

        const response = await API.isReservationAvailable(
          lineId,
          redirectConfig.state.selectedHour,
        )

        if (response.isAvailable) {
          history.push(getPath(selectedReservation))
        } else {
          message.error(
            `No se puede realizar la reserva en este momento. Recargue la página y reintente.`,
          )
        }
      } else {
        history.push(getPath(selectedReservation))
      }
    } catch (error) {
      message.error(error || `Ocurrió un error al verificar la reserva.`)
    }
  }

  const endDay = availableDays[availableDays.length - 1]
    ? moment(availableDays[availableDays.length - 1])
    : null
  return (
    <Container width="100%" maxWidth="700px" justifyContent="center">
      <Container
        width="100%"
        height="3rem"
        borderRadius="5px 5px 0 0"
        backgroundColor="primary.2"
        justifyContent="center"
        alignItems="center"
        marginBottom="1"
        position="relative"
      >
        {historyStack[0].dayOfYear() !== currentDate.dayOfYear() && (
          <Container position="absolute" left={[`5px`, `5px`, `2rem`, `2rem`]}>
            <Typography color="white" fontSize={[`0`, `0`, `2`, `2`]}>
              <Icon
                icon="chevronLeft"
                color="white"
                marginRight="1"
                cursor="pointer"
                onClick={isLoading ? null : handleBackPress}
              />
              atrás
            </Typography>
          </Container>
        )}
        <Typography
          color="white"
          fontSize={{ _: 2, md: 4, lg: 7, xl: 8 }}
          onClick={openCalendar}
        >
          {isLoading ? (
            `Buscando...`
          ) : (
            <Container onClick={openCalendar}>
              {currentDate.format(`dddd, ll`)}
              <Icon
                marginLeft={1}
                icon="calendar"
                color="white"
                onClick={openCalendar}
                cursor="pointer"
              />
            </Container>
          )}
        </Typography>
        {endDay == null || endDay.dayOfYear() !== currentDate.dayOfYear() ? (
          <Container
            position="absolute"
            right={[`5px`, `5px`, `2rem`, `2rem`]}
            onClick={isLoading ? null : handleForwardPress}
            color="white"
          >
            <Typography color="white" fontSize={[`0`, `0`, `2`, `2`]}>
              siguiente
              <Icon
                icon="chevronRight"
                color="white"
                cursor="pointer"
                float="right"
                marginLeft="1"
              />
            </Typography>
          </Container>
        ) : null}
      </Container>
      <Container
        backgroundColor="grey.4@0.5"
        width="100%"
        justifyContent="center"
        paddingTop="3"
        paddingBottom="3"
      >
        {isLoading ? (
          <Icon icon="spinner" spin />
        ) : hours.length === 0 ? (
          <Typography color="primary.0">
            No se encontraron horas disponibles
          </Typography>
        ) : hours ? (
          <Container
            width="90%"
            maxHeight={[`45vh`, `45vh`, `50vh`, `50vh`]}
            overflowY="scroll"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            paddingRight="3"
          >
            {hours.map(hourElement => {
              const { id, hour, active, styles, statusCount } = hourElement
              const selected =
                selectedReservation === id ? stylesDictionary.selected : styles
              return (
                <Button
                  key={`${id}`}
                  disabled={!active}
                  padding={{ _: 2, md: 3 }}
                  width={{ _: `47%`, md: `18%` }}
                  marginBottom="1em"
                  borderRadius="5px"
                  onClick={() => setState({ selectedReservation: id })}
                  {...selected}
                >
                  <>
                    <Container position="relative">
                      <Popover
                        content={<HourDetail {...statusCount} />}
                        title="Detalles"
                        trigger="click"
                      >
                        <Icon
                          fontSize="3"
                          icon="info"
                          position="absolute"
                          top="6px"
                          right="0"
                        />
                      </Popover>
                    </Container>
                    <Typography
                      fontSize={{ _: 5, lg: 6, xl: 7 }}
                      marginRight="3"
                      color={
                        selectedReservation == id ? `white` : `secundary.1`
                      }
                    >
                      {moment(hour).format(`LT`)}
                    </Typography>
                  </>
                </Button>
              )
            })}
          </Container>
        ) : (
          <Icon icon="loader" spin margin="0 auto" fontSize="8" />
        )}
      </Container>
      <Container
        backgroundColor="grey.4@0.5"
        width="100%"
        padding="3"
        borderTop="1px solid"
        borderColor="grey.2"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          onClick={handleScheduleClick}
          width="50%"
          padding={{ _: 3, md: 3 }}
          hoverProps={
            selectedReservation ? { backgroundColor: `primary.2@0.9` } : null
          }
          borderRadius="5px"
          withShadow={selectedReservation}
          backgroundColor={selectedReservation ? `primary.2` : `grey.2`}
          color={selectedReservation ? `white` : `grey.4`}
        >
          <Typography
            textDecoration="none !important"
            color={selectedReservation ? `white` : `grey.3`}
            fontSize={{ _: 4, lg: 4 }}
          >
            Ok, programar
          </Typography>
        </Button>
      </Container>
    </Container>
  )
}

export default withRouter(HourPicker)
