/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef } from 'react'
import { clean } from 'rut.js'
import moment from 'moment-timezone'
import { useStateWithMerge, useBack } from 'hooks'
import { useCorporation } from 'context'
import ReCAPTCHA from 'react-google-recaptcha'
import { Container, Typography, Icon, Link, Form, Button } from 'components'
import message from 'antd/es/message'
import API from 'config/apiAgenda'

function SuccessScreen({
  history,
  date,
  patientMail,
  scheduleUnit,
  scheduleUser,
  groupByProfessional,
}) {
  const goBack = useBack()
  return (
    <Container
      width="100%"
      flexDirection="column"
      alignItems={{ md: `center` }}
      paddingX={4}
    >
      <Container
        width={{ _: `100%`, md: `35%` }}
        flexDirection="column"
        alignItems="flex-start"
      >
        <Container width="100%" flexDirection="column">
          <Typography
            color="primary.2"
            fontSize="6"
            fontWeight="bold"
            fontStyle="italic"
            marginY="10px"
          >
            ¡SU HORA SE HA AGENDADO CON EXITO!
          </Typography>
          <Typography
            color="primary.2"
            fontSize="4"
            fontStyle="italic"
            marginY="10px"
          >
            {`Enviado al correo registrado: ${patientMail}`}
          </Typography>
        </Container>
        <Container
          flexDirection="column"
          width="100%"
          border="1px solid"
          borderColor="primary.2"
          padding="2"
        >
          <Typography
            color="primary.2"
            fontSize="4"
            fontWeight="bold"
            textAlign="center"
            marginTop="2"
            marginBottom="4"
            paddingX="2"
          >
            Fecha:{` `}
            {moment(date).format(`LL`)}
          </Typography>
          <Container
            width="97%"
            height="2px"
            backgroundColor="primary.2"
            margin="0 auto"
          />
          <Typography
            color="primary.2"
            fontSize="4"
            fontWeight="bold"
            textAlign="center"
            marginY="4"
            paddingX="2"
          >
            Hora: {moment(date).format(`H:mm`)} hrs.
          </Typography>
          <Container
            width="97%"
            height="2px"
            backgroundColor="primary.2"
            margin="0 auto"
          />
          <Typography
            color="primary.2"
            fontSize="4"
            fontWeight="bold"
            textAlign="center"
            marginTop="4"
            marginBottom="2"
            paddingX="2"
          >
            Área: {scheduleUnit.name}
          </Typography>
          {groupByProfessional && (
            <Container width="100%" flexDirection="column" marginTop="3">
              <Container
                width="97%"
                height="2px"
                backgroundColor="primary.2"
                margin="0 auto"
              />

              <Typography
                color="primary.2"
                fontSize="4"
                fontWeight="bold"
                textAlign="center"
                marginTop="4"
                marginBottom="2"
                paddingX="2"
              >
                {`Profesional: ${scheduleUser.user.firstName} ${scheduleUser.user.lastName}`}
              </Typography>
            </Container>
          )}
        </Container>
        <Container width="100%" justifyContent="space-between" marginTop="20px">
          <Button
            padding="10px"
            paddingX="2"
            backgroundColor="primary.2"
            width="49%"
            onClick={goBack}
          >
            VOLVER A RESERVAR
          </Button>
          <Button
            padding="10px"
            backgroundColor="primary.2"
            width="49%"
            onClick={() => window.print()}
          >
            <Icon icon="print" marginRight="5px" />
            IMPRIMIR
          </Button>
        </Container>
        <Typography textAlign="center" color="error" marginY="3" paddingX="2">
          Le recordamos tomar una captura de pantalla para guardar su reserva y
          presentarla al llegar.
        </Typography>
        <Link
          onClick={() => {
            history.push(`/`)
          }}
          color="primary.0"
          hoverProps={{ color: `secundary.0` }}
          fontSize={{ _: 1, lg: 4 }}
          fontStyle="italic"
          justifyContent="center"
          width="100%"
        >
          <Typography>Entendido, volver al inicio.</Typography>
        </Link>
      </Container>
    </Container>
  )
}

function getInitialState({ scheduleUnit, patient, scheduleUser }) {
  const formType =
    scheduleUnit && scheduleUnit.patientValidationType === 2
      ? `withValidatePatient`
      : `withoutValidatePatient`

  return {
    patientMail: (patient && patient.patientMail) || `sinmail@saltala.com`,
    patientRut: (patient && patient.patientRut) || null,
    isLoading: false,
    success: false,
    scheduleUnit: scheduleUnit ? scheduleUnit : {},
    scheduleUser: scheduleUser ? scheduleUser : {},
    formSchema:
      scheduleUnit &&
      scheduleUnit.formsConfig &&
      scheduleUnit.formsConfig.schedule
        ? scheduleUnit.formsConfig.schedule[formType]
        : {},
    groupByProfessional: null,
    siteKey: null,
  }
}

function AppointmentRequest({ history, match, location }) {
  const [state, setState] = useStateWithMerge(
    getInitialState(location.state ? location.state : {}),
  )
  const recaptchaRef = useRef(null)
  // ESTO ES SOLO PARA HOTFIX DE LAS CONDES
  // SE BLOQUEAN INPUTS SOLO PARA LAS CONDES
  const { id: corporationId } = useCorporation()
  const disabledInputs = [1].includes(corporationId)

  const {
    patientMail,
    isLoading,
    success,
    scheduleUnit,
    scheduleUser,
    formSchema,
    groupByProfessional,
  } = state

  const preloadedFormValues =
    location.state && location.state.patient
      ? { ...location.state.patient }
      : {}

  useEffect(() => {
    async function getUnit() {
      try {
        const scheduleUnitData =
          location.state && location.state.scheduleUnit
            ? location.state.scheduleUnit
            : await API.getScheduleUnit(
                parseInt(match.params.scheduleUnitId, 10),
              )
        const response = await API.getInvisibleSiteKey()
        if (
          scheduleUnitData.publicConfig.groupByProfessional &&
          !location.state
        ) {
          const professionalSelectUrl = match.url.split(`agendar`)
          return history.push(`${professionalSelectUrl[0]}agendar`)
        }

        const formType =
          scheduleUnitData && scheduleUnitData.patientValidationType == 2
            ? `withValidatePatient`
            : `withoutValidatePatient`

        // Si la unidad requiere validacion y el paciente no fue validado
        // previamente entonces, redirige a la screen de validacion
        if (scheduleUnitData.patientValidationType === 2 && !location.state) {
          const validationPath = location.pathname.split(`/`)
          validationPath.pop()
          return history.push(validationPath.join(`/`))
        }

        setState({
          scheduleUnit: scheduleUnitData,
          groupByProfessional:
            scheduleUnitData.publicConfig.groupByProfessional,
          formSchema: scheduleUnitData.formsConfig.schedule[formType],
          siteKey: response.invisibleCaptchaSiteKey,
        })
      } catch (e) {
        console.log(e)
      }
    }
    getUnit()
  }, [])

  async function handleFormSubmit(patient) {
    if (!recaptchaRef.current) {
      message.error(`reCAPTCHA no está listo. Por favor, intente nuevamente.`)
      return
    }
    try {
      const token = await recaptchaRef.current.executeAsync()
      if (token) {
        await generateAppointment(patient, token)
      } else {
        message.error(
          `No se pudo obtener el token de reCAPTCHA. Por favor, intente nuevamente.`,
        )
      }
    } catch (error) {
      message.error(
        `Error al verificar reCAPTCHA. Por favor, intente nuevamente.`,
      )
    }
  }

  async function generateAppointment(patient, googleToken) {
    setState({ isLoading: true, error: null })
    try {
      const payload = {
        ...patient,
        patientRut: clean(patient.patientRut),
        scheduleUnitId: match.params.scheduleUnitId,
        scheduleUserId: scheduleUser.id,
        groupByProfessional: groupByProfessional ? groupByProfessional : null,
        reservationDate: moment(match.params.reservationDate).format(
          `YYYY-MM-DD HH:mm`,
        ),
        googleToken,
      }

      await API.makeAppointment(payload)

      setState({
        patientMail: patient.patientMail,
        isLoading: false,
        success: true,
      })
    } catch (e) {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset()
      }
      const error =
        typeof e == `string`
          ? e
          : `Ha ocurrido un error al obtener la información`
      message.error(e)
      setState({ error, isLoading: false, succes: false })
      history.goBack()
    }
  }

  return success ? (
    <SuccessScreen
      history={history}
      scheduleUnit={scheduleUnit}
      scheduleUser={scheduleUser}
      groupByProfessional={groupByProfessional}
      date={moment(match.params.reservationDate).format(`YYYY-MM-DD HH:mm`)}
      patientMail={patientMail}
    />
  ) : isLoading || scheduleUnit == null ? (
    <Container width="100%" justifyContent="center" alignItems="center">
      <Icon icon="spinner" spin color="primary.2" fontSize="10" />
    </Container>
  ) : (
    <>
      <Container
        width="100%"
        flexDirection="column"
        alignItems="center"
        paddingX={4}
      >
        <Container
          width="100%"
          paddingBottom="4"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Typography
            width="100%"
            color="primary.0"
            fontSize={{ _: 5, lg: 8 }}
            fontWeight="bold"
          >
            Confirmación de datos personales
          </Typography>
        </Container>
        <Form
          disabledInputs={disabledInputs}
          maxWidth="700px"
          formSchema={formSchema ? formSchema : {}}
          defaultValues={preloadedFormValues}
          okText="Aceptar y finalizar"
          onConfirm={handleFormSubmit}
          isLoading={isLoading}
        />
        {state.siteKey && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={state.siteKey}
            size="invisible"
          />
        )}
      </Container>
    </>
  )
}

export default AppointmentRequest
